@media (min-width: 991.98px) {
  main {
    padding-left: 240px;

  }
}


.navbar-brand {
  margin-bottom: 60px;
}





.list-group-item {
  margin-bottom: 15px;
}



.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 30px 0 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 380px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 100%;
    height: 100% !important;
    border-radius: 0;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-group-item.active {
  background-color: #E7272D !important;
  z-index: 2;
  color: #FFFFFF !important;
  border-color: #E7272D !important;
}

.list-group-item {
  border: none !important;
}



.btn-danger {
  height: 40px;
  border-radius: 9px !important;

}



.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
  background: #f3f0f3;
}

.search input {

  height: 40px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
  border-radius: 30px;

}




.search .fa-search {

  position: absolute;
  top: 15px;
  left: 16px;

}



.search button {

  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  width: 80px;
  border: none;


}